const config = {
  CLOUDINARY_PROJECT_ICON_PATH: "https://res.cloudinary.com/dsr37ut2z/image/upload/assets/project-icons/",
  CLOUDINARY_PROJECT_BACKGROUND_PATH:
    "https://res.cloudinary.com/dsr37ut2z/image/upload/assets/backgrounds/ecosystems/",
};

export const proposedMapping: Record<string, string> = {
  "axelar.core.ez_satellite": "axelar.defi.ez_bridge_satellite",
  "axelar.core.ez_squid": "axelar.defi.ez_bridge_squid",
  "axelar.core.fact_governance_proposal_deposits": "axelar.gov.fact_governance_proposal_deposits",
  "axelar.core.fact_governance_submit_proposal": "axelar.gov.fact_governance_submit_proposal",
  "axelar.core.fact_governance_votes": "axelar.gov.fact_governance_votes",
  "axelar.core.fact_staking_rewards": "axelar.gov.fact_staking_rewards",
  "axelar.core.fact_staking": "axelar.gov.fact_staking",
  "axelar.core.fact_validator_commission": "axelar.gov.fact_validator_commission",
  "axelar.core.fact_validators": "axelar.gov.fact_validators",
  "cosmos.core.dim_vote_options": "cosmos.gov.dim_vote_options",
  "cosmos.core.fact_governance_proposal_deposits": "cosmos.gov.fact_governance_proposal_deposits",
  "cosmos.core.fact_governance_submit_proposal": "cosmos.gov.fact_governance_submit_proposal",
  "cosmos.core.fact_governance_votes": "cosmos.gov.fact_governance_votes",
  "cosmos.core.fact_validators": "cosmos.gov.fact_validators",
  "osmosis.core.dim_liquidity_pools": "osmosis.defi.dim_liquidity_pools",
  "osmosis.core.dim_prices": "osmosis.price.dim_prices",
  "osmosis.core.dim_vote_options": "osmosis.gov.dim_vote_options",
  "osmosis.core.ez_prices": "osmosis.price.ez_prices",
  "osmosis.core.fact_governance_proposal_deposits": "osmosis.gov.fact_governance_proposal_deposits",
  "osmosis.core.fact_governance_submit_proposal": "osmosis.gov.fact_governance_submit_proposal",
  "osmosis.core.fact_governance_validator_votes": "osmosis.gov.fact_governance_validator_votes",
  "osmosis.core.fact_governance_votes": "osmosis.gov.fact_governance_votes",
  "osmosis.core.fact_liquidity_provider_actions": "osmosis.defi.fact_liquidity_provider_actions",
  "osmosis.core.fact_locked_liquidity_actions": "osmosis.defi.fact_locked_liquidity_actions",
  "osmosis.core.fact_pool_fee_day": "osmosis.defi.fact_pool_fee_day",
  "osmosis.core.fact_pool_hour": "osmosis.defi.fact_pool_hour",
  "osmosis.core.fact_staking_rewards": "osmosis.gov.fact_staking_rewards",
  "osmosis.core.fact_staking": "osmosis.gov.fact_staking",
  "osmosis.core.fact_superfluid_staking": "osmosis.defi.fact_superfluid_staking",
  "osmosis.core.fact_swaps": "osmosis.defi.fact_swaps",
  "osmosis.core.fact_validator_commission": "osmosis.gov.fact_validator_commission",
  "osmosis.core.fact_validators": "osmosis.gov.fact_validators",
  "terra.classic.ez_staking": "terra.defi.ez_staking",
  "terra.classic.ez_swaps": "terra.defi.ez_swaps",
  "terra.core.fact_governance_submit_proposal": "terra.gov.fact_governance_submit_proposal",
  "terra.core.fact_governance_votes": "terra.gov.fact_governance_votes",
  "terra.core.fact_lp_actions": "terra.defi.fact_lp_actions",
  "terra.core.fact_nft_mints": "terra.nft.fact_nft_mints",
  "terra.core.fact_nft_sales": "terra.nft.fact_nft_sales",
  "terra.core.ez_staking": "terra.defi.ez_staking",
  "terra.core.ez_swaps": "terra.defi.ez_swaps",
  "terra.core.fact_messages": "terra.defi.ez_messages",
  "sei.core.fact_governance_proposal_deposits": "sei.gov.fact_governance_proposal_deposits",
  "sei.core.fact_governance_submit_proposal": "sei.gov.fact_governance_submit_proposal",
  "sei.core.fact_governance_votes": "sei.gov.fact_governance_votes",
  "sei.core.fact_staking": "sei.gov.fact_staking",
  "sei.core.fact_staking_rewards": "sei.gov.fact_staking_rewards",

  "arbitrum.core.fact_hourly_token_prices": "arbitrum.price.ez_hourly_token_prices",
  "arbitrum.core.ez_dex_swaps": "arbitrum.defi.ez_dex_swaps",
  "arbitrum.core.ez_nft_transfers": "arbitrum.nft.ez_nft_transfers",
  "arbitrum.core.ez_nft_sales": "arbitrum.nft.ez_nft_sales",

  "avalanche.core.fact_hourly_token_prices": "avalanche.price.ez_hourly_token_prices",
  "avalanche.core.ez_dex_swaps": "avalanche.defi.ez_dex_swaps",
  "avalanche.core.ez_nft_transfers": "avalanche.nft.ez_nft_transfers",
  "avalanche.core.ez_nft_sales": "avalanche.nft.ez_nft_sales",

  "base.core.ez_nft_transfers": "base.nft.ez_nft_transfers",
  "base.core.ez_nft_sales": "base.nft.ez_nft_sales",
  "base.core.fact_hourly_token_prices": "base.price.ez_hourly_token_prices",

  "bsc.core.fact_hourly_token_prices": "bsc.price.ez_hourly_token_prices",
  "bsc.core.ez_dex_swaps": "bsc.defi.ez_dex_swaps",
  "bsc.core.ez_nft_transfers": "bsc.defi.ez_nft_transfers",
  "bsc.core.ez_nft_sales": "bsc.defi.ez_nft_sales",
  "bsc.core.dim_dex_liquidity_pools": "bsc.defi.dim_dex_liquidity_pools",

  "gnosis.core.fact_hourly_token_prices": "gnosis.price.ez_hourly_token_prices",
  "gnosis.core.ez_nft_transfers": "gnosis.nft.ez_nft_transfers",

  "optimism.core.fact_hourly_token_prices": "optimism.price.ez_hourly_token_prices",
  "optimism.core.ez_dex_swaps": "optimism.defi.ez_dex_swaps",
  "optimism.core.ez_nft_transfers": "optimism.nft.ez_nft_transfers",
  "optimism.core.ez_nft_sales": "optimism.nft.ez_nft_sales",
  "optimism.core.fact_delegations": "optimism.gov.fact_delegations",

  "polygon.core.fact_hourly_token_prices": "polygon.price.ez_hourly_token_prices",
  "polygon.core.ez_dex_swaps": "polygon.defi.ez_dex_swaps",
  "polygon.core.ez_nft_transfers": "polygon.nft.ez_nft_transfers",
  "polygon.core.ez_nft_sales": "polygon.nft.ez_nft_sales",
  "polygon.core.ez_nft_mints": "polygon.nft.ez_nft_mints",

  "ethereum.core.fact_hourly_token_prices": "ethereum.price.ez_hourly_token_prices",
  "ethereum.core.ez_dex_swaps": "ethereum.defi.ez_dex_swaps",
  "ethereum.core.ez_nft_transfers": "ethereum.nft.ez_nft_transfers",
  "ethereum.core.ez_nft_sales": "ethereum.nft.ez_nft_sales",
  "ethereum.core.ez_nft_mints": "ethereum.nft.ez_nft_mints",
  "ethereum.core.dim_dex_liquidity_pools": "ethereum.defi.dim_dex_liquidity_pools",
  "ethereum.core.dim_nft_metadata": "ethereum.nft.dim_nft_metadata",
  "ethereum.core.ez_snapshot": "external.snapshot.ez_snapshot",
  "flow.core.dim_allday_metadata": "flow.nft.dim_allday_metadata",
  "flow.core.dim_moment_metadata": "flow.nft.dim_moment_metadata",
  "flow.core.dim_swap_pool_labels": "flow.defi.dim_swap_pool_labels",
  "flow.core.dim_topshot_metadata": "flow.nft.dim_topshot_metadata",
  "flow.core.dim_validator_labels": "flow.gov.dim_validator_labels",
  "flow.core.ez_bridge_transactions": "flow.defi.ez_bridge_transactions",
  "flow.core.ez_nft_sales": "flow.nft.ez_nft_sales",
  "flow.core.ez_staking_actions": "flow.gov.ez_staking_actions",
  "flow.core.ez_swaps": "flow.defi.ez_swaps",
  "flow.beta.ez_nft_topshot_packs": "flow.core.ez_nft_topshot_packs",
  "flow.core.ez_dex_swaps": "flow.defi.ez_swaps",
  "flow.core.fact_hourly_prices": "flow.price.fact_hourly_prices",
  "flow.core.fact_prices": "flow.price.fact_prices",
  "near.core.dim_staking_actions": "near.gov.dim_staking_actions",
  "near.core.dim_staking_pools": "near.gov.dim_staking_pools",
  "near.core.ez_dex_swaps": "near.defi.ez_dex_swaps",
  "near.core.ez_nft_mints": "near.nft.ez_nft_mints",
  "near.core.fact_lockup_actions": "near.gov.fact_lockup_actions",
  "near.core.fact_nft_mints": "near.nft.fact_nft_mints",
  "near.core.fact_prices": "near.price.fact_prices",
  "near.core.fact_staking_actions": "near.gov.fact_staking_actions",
  "near.core.fact_staking_pool_balances": "near.gov.fact_staking_pool_balances",
  "near.core.fact_staking_pool_daily_balances": "near.gov.fact_staking_pool_daily_balances",
  "solana.core.dim_nft_metadata": "solana.nft.dim_nft_metadata",
  "solana.core.ez_staking_lp_actions": "solana.gov.ez_staking_lp_actions",
  "solana.core.ez_token_prices_hourly": "solana.price.ez_token_prices_hourly",
  "solana.core.fact_block_production": "solana.gov.fact_block_production",
  "solana.core.fact_gauges_creates": "solana.gov.fact_gauges_creates",
  "solana.core.fact_gauges_votes": "solana.gov.fact_gauges_votes",
  "solana.core.fact_gov_actions": "solana.gov.fact_gov_actions",
  "solana.core.fact_liquidity_pool_actions": "solana.defi.fact_liquidity_pool_actions",
  "solana.core.fact_nft_mints": "solana.nft.fact_nft_mints",
  "solana.core.fact_nft_sales": "solana.nft.fact_nft_sales",
  "solana.core.fact_proposal_creation": "solana.gov.fact_proposal_creation",
  "solana.core.fact_proposal_votes": "solana.gov.fact_proposal_votes",
  "solana.core.fact_stake_accounts": "solana.gov.fact_stake_accounts",
  "solana.core.fact_stake_pool_actions": "solana.defi.fact_stake_pool_actions",
  "solana.core.fact_staking_lp_actions": "solana.gov.fact_staking_lp_actions",
  "solana.core.fact_swaps": "solana.defi.fact_swaps",
  "solana.core.fact_token_prices_hourly": "solana.price.fact_token_prices_hourly",
  "solana.core.fact_validators": "solana.gov.fact_validators",
  "solana.core.fact_vote_accounts": "solana.gov.fact_vote_accounts",
  "solana.core.fact_votes_agg_block": "solana.gov.fact_votes_agg_block",
  "thorchain.core.fact_active_vault_events": "thorchain.defi.fact_active_vault_events",
  "thorchain.core.fact_add_events": "thorchain.defi.fact_add_events",
  "thorchain.core.fact_asgard_fund_yggdrasil_events": "thorchain.defi.fact_asgard_fund_yggdrasil_events",
  "thorchain.core.fact_block_pool_depths": "thorchain.defi.fact_block_pool_depths",
  "thorchain.core.fact_block_rewards": "thorchain.defi.fact_block_rewards",
  "thorchain.core.fact_bond_actions": "thorchain.defi.fact_bond_actions",
  "thorchain.core.fact_bond_events": "thorchain.defi.fact_bond_events",
  "thorchain.core.fact_daily_earnings": "thorchain.defi.fact_daily_earnings",
  "thorchain.core.fact_daily_pool_stats": "thorchain.defi.fact_daily_pool_stats",
  "thorchain.core.fact_daily_tvl": "thorchain.defi.fact_daily_tvl",
  "thorchain.core.fact_errata_events": "thorchain.defi.fact_errata_events",
  "thorchain.core.fact_fee_events": "thorchain.defi.fact_fee_events",
  "thorchain.core.fact_gas_events": "thorchain.defi.fact_gas_events",
  "thorchain.core.fact_inactive_vault_events": "thorchain.defi.fact_inactive_vault_events",
  "thorchain.core.fact_liquidity_actions": "thorchain.defi.fact_liquidity_actions",
  "thorchain.core.fact_new_node_events": "thorchain.gov.fact_new_node_events",
  "thorchain.core.fact_outbound_events": "thorchain.defi.fact_outbound_events",
  "thorchain.core.fact_pending_liquidity_events": "thorchain.defi.fact_pending_liquidity_events",
  "thorchain.core.fact_pool_balance_change_events": "thorchain.defi.fact_pool_balance_change_events",
  "thorchain.core.fact_pool_block_balances": "thorchain.defi.fact_pool_block_balances",
  "thorchain.core.fact_pool_block_fees": "thorchain.defi.fact_pool_block_fees",
  "thorchain.core.fact_pool_block_statistics": "thorchain.defi.fact_pool_block_statistics",
  "thorchain.core.fact_pool_events": "thorchain.defi.fact_pool_events",
  "thorchain.core.fact_prices": "thorchain.price.fact_prices",
  "thorchain.core.fact_refund_events": "thorchain.defi.fact_refund_events",
  "thorchain.core.fact_reserve_events": "thorchain.defi.fact_reserve_events",
  "thorchain.core.fact_rewards_event_entries": "thorchain.defi.fact_rewards_event_entries",
  "thorchain.core.fact_rewards_events": "thorchain.defi.fact_rewards_events",
  "thorchain.core.fact_set_ip_address_events": "thorchain.gov.fact_set_ip_address_events",
  "thorchain.core.fact_set_node_keys_events": "thorchain.gov.fact_set_node_keys_events",
  "thorchain.core.fact_set_version_events": "thorchain.gov.fact_set_version_events",
  "thorchain.core.fact_slash_amounts": "thorchain.gov.fact_slash_amounts",
  "thorchain.core.fact_slash_points": "thorchain.gov.fact_slash_points",
  "thorchain.core.fact_stake_events": "thorchain.defi.fact_stake_events",
  "thorchain.core.fact_swaps_events": "thorchain.defi.fact_swaps_events",
  "thorchain.core.fact_swaps": "thorchain.defi.fact_swaps",
  "thorchain.core.fact_switch_events": "thorchain.defi.fact_switch_events",
  "thorchain.core.fact_total_block_rewards": "thorchain.defi.fact_total_block_rewards",
  "thorchain.core.fact_total_value_locked": "thorchain.defi.fact_total_value_locked",
  "thorchain.core.fact_transfers": "thorchain.core.fact_transfers",
  "thorchain.core.fact_unstake_events": "thorchain.deprecating.fact_unstake_events",
  "thorchain.core.fact_update_node_account_status_events": "thorchain.gov.fact_update_node_account_status_events",
  "thorchain.core.fact_upgrades": "thorchain.defi.fact_upgrades",
  "thorchain.core.fact_validator_request_leave_events": "thorchain.gov.fact_validator_request_leave_events",
  "thorchain.core.fact_withdraw_events": "thorchain.defi.fact_withdraw_events",
  "thorchain.core.fact_mint_burn_events": "thorchain.defi.fact_mint_burn_events",
  "thorchain.core.fact_loan_open_events": "thorchain.defi.fact_loan_open_events",
  "bitcoin.core.ez_miner_rewards": "bitcoin.gov.ez_miner_rewards",
  "crosschain.core.address_labels": "crosschain.core.dim_labels",
  "crosschain.core.address_tags": "crosschain.core.dim_tags",
  "crosschain.core.dim_asset_metadata": "crosschain.price.dim_asset_metadata",
  "crosschain.core.ez_asset_metadata": "crosschain.price.ez_asset_metadata",
  "crosschain.core.ez_dex_swaps": "crosschain.defi.ez_dex_swaps",
  "crosschain.core.ez_ens": "crosschain.ens.ez_ens",
  "crosschain.core.ez_hourly_prices": "crosschain.price.ez_hourly_token_prices",
  "crosschain.core.fact_dex_swaps": "crosschain.defi.fact_dex_swaps",
};

export default config;
